<template>
  <div class="unusual-warning has-nav-bar">
    <van-nav-bar
      title="作业异常预警"
      fixed
      left-arrow
      @click-left="$router.back()"
    >
    </van-nav-bar>
    <van-tabs v-model="active" swipeable>
      <van-tab
        v-for="item in tabList"
        :key="item.name"
        :title="item.title"
        :name="item.name"
      ></van-tab>
    </van-tabs>
    <div class="trouble-total">共{{ total }}条视频报警记录</div>
    <van-pull-refresh
      v-model="isPullRefreshing"
      class="video-alarm-list__wrapper"
      @refresh="onRefresh"
    >
      <van-list
        v-model="isLoading"
        :finished="isFinished"
        :finished-text="isError ? '' : '没有更多了'"
        :error.sync="isError"
        error-text="加载失败，请稍后再试！"
        @load="getList"
        ><span v-if="active === '3'">
          <van-cell
            v-for="(item, idx) in list"
            :key="idx"
            :class="[
              'video-alarm-list__rows',
              idx === list.length - 1 && 'border-none'
            ]"
            :border="false"
            @click="gotoDetails(item)"
          >
            <div>
              <img
                class="alarm-image"
                :src="getFileUrl(item.signImg)"
                alt="报警图片"
                @click.stop="onShowImage(item.signImg)"
              />
              <div class="content">
                <h3 class="title">
                  {{ item.videoName }}
                </h3>
                <div
                  class="status-icon"
                  :style="{ color: iconConf[item.checkStatus].color }"
                >
                  <van-icon
                    :name="iconConf[item.checkStatus].name"
                    class-prefix="iconfont"
                  />
                </div>
                <div class="type-text">
                  <span>{{ item.alarmType }}</span>
                  <span>{{ item.alarmStatus }}</span>
                </div>
                <p class="time-text">{{ item.alarmStartTime }}</p>
              </div>
            </div>
            <div class="line-block" />
          </van-cell>
        </span>
        <div
          v-for="item in alarmList"
          v-else
          :key="item.id + item.handleStatus"
          class="alarm-item"
          @click="gotoAlarmDetail(item.id)"
        >
          <div class="alarm-header">
            <div class="alarm-title">
              <div class="alarm-name-box">
                <img
                  v-if="item.handleStatus === '0'"
                  class="iconfont-baojingyuzhi"
                  src="../../assets/images/alarm.png"
                />
                <span class="alarm-name"
                  >{{ item.indicatorName }} ({{ item.indicatorNum }})</span
                >
              </div>
              <div class="status-box">
                <img
                  class="second-icon"
                  src="../../assets/images/equipment.png"
                />
                <span class="equipment-name">{{
                  item.equipmentName || "-"
                }}</span>
                <span class="status-color-box">{{ item.alarmStatusName }}</span>
                <span class="status-color-box type-color">{{
                  item.indicatorTypeName
                }}</span>
              </div>
            </div>
            <div
              class="alarm-status"
              :style="
                `color: ${disposalStatusIconColorSwitch[item.checkStatus]}`
              "
            >
              <!--              <img :src="disposalStatusImgSrcSwitch(item.checkStatus)" />-->
              <van-icon
                :name="disposalStatusImgSrcSwitch(item.checkStatus)"
                class-prefix="iconfont"
                class="trouble-status-icon"
              />
            </div>
          </div>
          <div class="alarm-time">
            <span>
              <img class="second-icon" src="../../assets/images/date.png" />
              <span class="date-text">{{ item.firstAlarmTime }}</span>
            </span>
            <span>
              <img class="second-icon" src="../../assets/images/time.png" />
              <span class="date-text">{{ item.alarmTime }}</span>
            </span>
          </div>
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import dayjs from "dayjs";
import {
  getWarningRecordVideoPage,
  getWarningRecordTicketPage,
  getWarningRecordTicketInfo
} from "@/api/workticket";
import { getPageVideoAlarm } from "@/api/warning";
import alarm from "@/api/alarm";
import { getFileUrlForFileSystem } from "@/utils/file";
import { iconConf } from "./utils/constant";
import { mapState } from "vuex";
import { ImagePreview } from "vant";
export default {
  name: "UnusualWarning",
  data() {
    return {
      alarmList: [],
      iconConf,
      queryInfo: {
        page: 1,
        size: 10,
        ticketId: this.$route.params.id
      },
      dateTime: { start: "", end: "" },
      total: 0,
      list: [],
      // 下拉刷新，上拉加载
      isPullRefreshing: false,
      isLoading: false,
      isFinished: true,
      isError: false,
      urls: [],
      active: "",
      tabList: [
        // {
        //   title: "工艺预警",
        //   name: "1",
        // },
        // {
        //   title: "可燃有毒预警",
        //   name: "2",
        // },
        // {
        //   title: "安全帽预警",
        //   name: "3",
        // },
      ]
    };
  },
  computed: {
    ...mapState({
      userInfo: state => state.login.userInfo,
      needRefreshList: state => state.alarm.needRefreshList
    }),
    disposalStatusImgSrcSwitch() {
      return checkStatus => {
        const obj = {
          0: "yihulue",
          1: "daiqueren",
          2: "daichuzhi",
          3: "daiheyan",
          4: "yiwancheng"
        };
        return obj[checkStatus];
      };
    },
    disposalStatusIconColorSwitch() {
      return {
        0: "#aeb3c0",
        1: "#5c7ba2",
        2: "#dea148",
        3: "#6448d7",
        4: "#8eb433"
      };
    }
  },
  watch: {
    active: {
      handler(val) {
        this.onRefresh();
      }
    }
  },
  async created() {
    this.queryInfo.orgCode = this.userInfo.orgCode;
    var data = await getWarningRecordTicketInfo({
      ticketId: this.$route.params.id
    });
    //当前暂无监护人离场预警功能，不展示
    this.tabList = data.warningTypeList.filter(item => {
      return item.value !== "4";
    });
    this.$nextTick(() => {
      this.tabList.forEach(item => {
        item.name = item.value;
        item.title = item.label;
      });
    });
    this.active = this.tabList[0].name;
  },
  methods: {
    getFileUrl(fileId) {
      return getFileUrlForFileSystem(fileId, false);
    },
    onShowImage(url) {
      url = this.getFileUrl(url);
      this.urls.push(url);
      ImagePreview(this.urls);
    },
    onRefresh() {
      this.queryInfo.page = 1;
      this.total = 0;
      this.list = [];
      this.alarmList = [];
      this.getList();
    },
    async getList() {
      this.isFinished = false;
      this.isLoading = true;
      if (this.isPullRefreshing) {
        this.isPullRefreshing = false;
      }

      try {
        let data;
        let params = { ...this.queryInfo, warningType: this.active };
        if (this.active === "3") {
          data = await getWarningRecordVideoPage(params);
        } else {
          data = await getWarningRecordTicketPage(params);
        }
        const { list, total } = data || { list: [], total: 0 };
        this.total = total;
        this.isError = false;
        this.isLoading = false;
        if (list && list.length) {
          if (this.active === "3") {
            this.list = this.list.concat(list);
          } else {
            this.alarmList = this.alarmList.concat(list);
          }
          this.queryInfo.page++;
        } else {
          this.isFinished = true;
        }
      } catch (error) {
        console.log("getList -> error", error);
        this.isLoading = false;
        this.isFinished = true;
        this.isError = true;
      }
    },
    gotoAlarmDetail(id) {
      this.$router.push({
        name: "AlarmDetail",
        params: { id },
        query: { showButton: false }
      });
    },
    gotoDetails({ alarmId: id }) {
      this.$router.push({
        name: "VideoAlarmDetail",
        params: { id },
        query: { showButton: false }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.unusual-warning {
  height: 100vh;
  display: flex;
  flex-direction: column;
  .video-alarm-list__wrapper {
    overflow-y: auto;
  }
  .trouble-total {
    text-align: center;
    color: #8c8f97;
    font-size: 14px;
    padding: 14px 0;
  }
  &__wrapper {
    overflow-y: auto;
  }
  .van-cell__value > div {
    padding-left: 16px;
    padding-right: 16px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    .alarm-image {
      width: 104px;
      height: 80px;
      border-radius: 6px;
      display: block;
      font-size: 0;
      margin-right: 12px;
    }

    .content {
      position: relative;
      flex: 1;
      color: #3b4664;
      .title {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        line-height: 20px;
        font-size: 14px;
      }
      .type-text {
        font-size: 12px;
        /*line-height: 1;*/
        span:last-child {
          color: #fff;
          background-color: #fb7171;
          padding: 2px 4px;
          border-radius: 2px;
          margin-left: 4px;
        }
      }
      .time-text {
        position: absolute;
        bottom: 0;
        width: 180px;
        font-size: 12px;
        line-height: 22px;
        color: #aeb3c0;
      }
      .status-icon {
        float: right;
        i {
          display: block;
          line-height: 40px;
          font-size: 40px;
        }
      }
    }
  }
  .line-block {
    margin: 0 -16px;
  }
  .alarm-item {
    // margin: 5.6vw;
    // border-bottom: 1px solid rgba(235, 235, 235, 1);
    // padding-bottom: 2vh;
    padding: 11px 17px 13px 16px;
    border-bottom: 8px solid #eceef2;
    font-size: 4vw;
    .alarm-header {
      display: flex;
      .alarm-title {
        flex: 3;
        max-width: 70vw;
        div {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          line-height: 1.1;
        }
        color: #3b4664;
        .alarm-name-box {
          margin: 0 0 10px;
          .iconfont-baojingyuzhi {
            margin: 0 4px 0 0;
            vertical-align: sub;
          }
          .alarm-name {
            height: 20px;
            font-size: 14px;
            font-weight: 500;
            color: #3b4664;
            line-height: 20px;
          }
        }
        .status-box {
          padding: 0 0 0 3px;
          margin: 0 0 6px;
          .equipment-name {
            font-size: 12px;
            font-weight: 400;
            color: #3b4664;
            line-height: 17px;
            margin: 0 20px 0 0;
          }
          .status-color-box {
            display: inline-block;
            font-size: 11px;
            font-weight: 400;
            color: #8b4fee;
            line-height: 16px;
            padding: 0 6px 0;
            margin: 0 8px 0 0;
            background: #ede2ff;
            border: 1px solid #8b4fee;
            border-radius: 3px;
          }
          .type-color {
            color: #00b6d6;
            background: #d8feff;
            border: 1px solid #00b6d6;
          }
        }
        .second-icon {
          margin: 0 4px 0 0;
          vertical-align: middle;
        }
      }
      .alarm-status {
        flex: 1;
        /*font-size: 100vw;*/
        text-align: right;
        padding: 7px 0 0;

        .trouble-status-icon {
          display: inline-block;
          text-align: center;
          width: 45px;
          height: 45px;
          line-height: 45px;
          font-size: 40px;
          &::after {
            display: inline-block;
            width: inherit;
            height: inherit;
          }
        }
      }
    }
    .alarm-time {
      color: #3b4664;
      padding: 0 0 0 3px;
      .second-icon {
        margin: 0 4px 0 0;
        vertical-align: middle;
      }
      .date-text {
        font-size: 12px;
        font-weight: 400;
        line-height: 17px;
        margin: 0 20px 0 0;
      }
    }
  }
}
</style>
